<template>
  <div class="friendlink">
    <Header ref="pagedetail"></Header>
    <main class="layout content-inner">
      <div id="page">
        <div class="flink" id="article-container">
          <h2>友情链接</h2>
          <div class="flink-list">
            <div class="flink-list-item">
              <a href="https://blog.likepoems.com/" title="基于vue3的个人博客" target="_blank">
                <img
                  src="https://blog.likepoems.com/images/tou2.png"
                />
                <span class="flink-item-name">基于vue3的个人博客</span>
                <span class="flink-item-desc" title="学习笔记">学习笔记</span>
              </a>
            </div>
            <div class="flink-list-item">
              <a href="https://backblog.likepoems.com" title="基于node的博客后台管理系统" target="_blank">
                <img
                  src="https://blog.likepoems.com/images/tou2.png"
                />
                <span class="flink-item-name">基于node的博客后台管理系统</span>
                <span class="flink-item-desc" title="学习笔记">学习笔记</span>
              </a>
            </div>
            <div class="flink-list-item">
              <a href="https://blog.csdn.net/likepoems" title="CSDN个人主页" target="_blank">
                <img
                  src="https://blog.likepoems.com/images/tou2.png"
                />
                <span class="flink-item-name">CSDN个人主页</span>
                <span class="flink-item-desc" title="学习笔记">学习笔记</span>
              </a>
            </div>
            <div class="flink-list-item">
              <a href="https://likepoems.com" title="一缕阳光的个人主页" target="_blank">
                <img
                  src="https://blog.likepoems.com/images/tou2.png"
                />
                <span class="flink-item-name">一缕阳光's blog</span>
                <span class="flink-item-desc" title="学习笔记">学习笔记</span>
              </a>
            </div>
          </div>
          <h2>我的站点</h2>
          <div class="flink-list">
            <div class="flink-list-item">
              <a href="https://blog.likepoems.com/" title="基于vue3的个人博客" target="_blank">
                <img src="https://blog.likepoems.com/images/author.jpg" />
                <span class="flink-item-name">基于vue3的个人博客</span>
                <span class="flink-item-desc" title="基于vue3的个人博客"
                  >基于vue3的个人博客</span
                >
              </a>
            </div>
            <div class="flink-list-item">
              <a href="https://likepoems.com" title="一缕阳光's blog" target="_blank">
                <img src="https://blog.likepoems.com/images/author.jpg" />
                <span class="flink-item-name">一缕阳光's blog</span>
                <span class="flink-item-desc" title="一缕阳光的个人主页"
                  >一缕阳光's blog</span
                >
              </a>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <AsideContent></AsideContent>
    </main>
    <Footer></Footer>
    <ToTop></ToTop>
  </div>
</template>

<script>
import Header from "@/components/Header";
import AsideContent from "@/components/AsideContent";
import Footer from "@/components/Footer";
import ToTop from "@/components/ToTop";
export default {
  name: "Friendlink",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    AsideContent,
    ToTop,
  },
  methods: {
    setpagedetail() {
      this.$refs.pagedetail.$refs.pagedetail.innerText = '友情链接'
    },
  },
  mounted() {
    this.setpagedetail();
  },
};
</script>

<style scoped>
.Header {
  height: 60vh;
  background-image: url("https://blog.likepoems.com/images/7.png");
}
.content-inner {
  animation: main 1s;
}
.layout {
  display: flex;
  margin: 0 auto;
  padding: 2rem 15px;
  max-width: 1200px;
}
.layout.hide-aside {
  max-width: 1000px;
}
#page {
  align-self: flex-start;
  padding: 50px 40px;
  border-radius: 8px;
  background: #fff;
  width: 100%;
  box-shadow: 0 4px 8px 6px rgba(7, 17, 27, 0.06);
}
#page:hover {
  -webkit-box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
  box-shadow: 0 4px 12px 12px rgba(7, 17, 27, 0.15);
}
@media screen and (max-width: 768px) {
  .layout {
    flex-direction: column;
  }
}
#article-container {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
#article-container h2 {
  padding-left: 1.3rem;
  position: relative;
  margin: 1rem 0 0.7rem;
  color: #1f2d3d;
  font-weight: bold;
  font-size: 1.5rem;
  transition: all 0.2s ease-out;
}
#article-container h2:hover {
  padding-left: 1.8rem;
}
#article-container h2:before {
  margin-left: -1.6rem;
  font-size: 1.5rem;
  position: absolute;
  top: calc(50% - 0.35rem);
  color: #f47466;
  content: "\f0e7";
  line-height: 0.6;
  transition: all 0.2s ease-out;
}
#article-container .flink-list {
  overflow: auto;
  padding: 10px 10px 0;
  text-align: center;
}
#article-container .flink-list > .flink-list-item {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 15px 7px;
  width: calc(100% / 3 - 15px);
  height: 90px;
  border-radius: 8px;
  line-height: 17px;
  border: 1px dashed #49b1f5 !important;
  -webkit-transform: translateZ(0);
}
#article-container .flink-list > .flink-list-item:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #49b1f5;
  content: "";
  transition: transform 0.3s ease-out;
  transform: scale(0);
}
#article-container .flink-list > .flink-list-item:hover img {
  transform: rotate(360deg);
}
#article-container .flink-list > .flink-list-item:hover:before,
#article-container .flink-list > .flink-list-item:focus:before,
#article-container .flink-list > .flink-list-item:active:before {
  transform: scale(1);
}
#article-container .flink-list > .flink-list-item a img {
  float: left;
  margin: 15px 10px;
  width: 60px;
  height: 60px;
  border-radius: 35px;
  transition: all 0.3s;
  max-width: 100%;
}
#article-container .flink-list > .flink-list-item a .flink-item-name {
  display: block;
  padding: 8px 10px 0 0;
  height: 50px;
  font-weight: bold;
  font-size: 1.45em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#article-container .flink-list > .flink-list-item a .flink-item-desc {
  display: block;
  padding-right: 10px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .layout {
    flex-direction: column;
  }
  #article-container .flink-list > .flink-list-item {
    width: calc(100% - 15px) !important;
  }
}
</style>